import { Outlet,useLocation} from 'react-router-dom'
import React ,{useEffect, useState} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Loader from '../../components/AAANEWCOMPONENT/Loader/Loader';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';


// const stripePromise = loadStripe(
//     "pk_live_51PkTCfFp8griUug77UvLvYYHn7kIeXj8Wmr4cEtoGvsBIWrCGNYiNBNJKGxstY3fkySnUBhJWGRLpt75GM8vXkS700f5IpX7JA"
//   );
  // const stripeTestPromise = loadStripe(
  //   "pk_test_51PkTCfFp8griUug7f8CAe5CnNGTCIYhoGPCP5YGOXlwJp6qmo5fYDnlW4QIHqIiJWHbG6ltvvSP8mrm6HqPLkYCk00B1cRvm67"
  // );
  // let STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
  let STRIPE_KEY = process.env.REACT_APP_STRIPE_TEST_KEY
  const stripePromise = loadStripe(`${STRIPE_KEY}`)
const PaymentForm = () => {
  const [clientSecret, setClientSecret] = useState("");
 const location = useLocation();
 const navigate = useNavigate();
 const {firstName,lastName,email,subFor,selectPlan,priceToDisplay,selectedData,msaRegions,pricePresent} = location.state || {};
  useEffect(() => {
    console.log(firstName,lastName,email,subFor,selectPlan,priceToDisplay,selectedData,msaRegions,"payment console")
    if(firstName === undefined || lastName === undefined || email === undefined){
     navigate("/subscription")
    }else{
    const finalAmountData = selectedData === null ? priceToDisplay : priceToDisplay*selectedData?.length;
    // let finalAmount = selectedData === null ? priceToDisplay : priceToDisplay*selectedData?.length;
    let amt = finalAmountData*100;
    const paymentUrl = process.env.REACT_APP_CREATE_PAYMENT_INTENT;
    fetch(paymentUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [{ id: "xl-tshirt", amount: amt, email: email }],
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        
        // setDpmCheckerLink(data.dpmCheckerLink);
      });
    }
  }, []);
  const appearance = {
    theme: 'stripe',
    variables: {
      fontSize: '11px',
    padding:'5px',
      fontFamily: 'Arial', // Font family for text
      fontWeight: '400', // Normal font weight
    },
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';
  return (
    <>
     {clientSecret ? (
        <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
          <CheckoutForm firstName={firstName} lastName={lastName} email={email} subFor={subFor} selectPlan={selectPlan}
        priceToDisplay={priceToDisplay}  selectedData={selectedData} msaRegions={msaRegions} clientSecret={clientSecret} 
        appearance={appearance} loader={loader} stripeTestPromise={stripePromise} pricePresent={pricePresent} />
        </Elements>
      ) : (
        <div className='checkout-loader'>
        <Loader />
        </div>
         // Or an error message
      )}
         {/* {clientSecret && (
        <Elements options={{ clientSecret, appearance, loader }} stripe={stripePromise}>
         <Outlet />
        </Elements>
      )} */}
        </>
  )
}

export default PaymentForm;
