import React, { useState, useEffect } from "react";
import { Avatar } from "primereact/avatar";
import "../pages/Stripe/StripeForm.css";
import "primereact/resources/primereact.min.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import MenuItem from "@material-ui/core/MenuItem";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const SubscriptionLayout = (WrappedComponent) => {
  return (props) => {
    return (
      <div className="subs">
        <div class="sub_footer" elevation={3}>
          <div class="Logo">
            <Avatar
              className="p-overlay-badge"
              image="\images\nedl_rgb.png"
              size="xlarge"
            ></Avatar>
          </div>
          <div class="cus-flex">
            <MenuItem
              className="sub_btn"
              onClick={() => window.open("https://nedl.us/", "_blank")}
            >
              Home
            </MenuItem>
            <MenuItem className="sub_btn sub_active">Pricing</MenuItem>
          </div>
          <div>
            <Button
              className="sub_active"
              name="login"
              onClick={() => window.open("/")}
            >
              Login
            </Button>
          </div>
        </div>
        <WrappedComponent {...props} />
        <div class="sub_footer">
          <div class="cus-flex">
            <MenuItem
              className="sub_btn"
              onClick={() =>
                window.open("https://www.instagram.com/nedl.us/", "_blank")
              }
            >
              <InstagramIcon />
            </MenuItem>
            <MenuItem
              className="sub_btn"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/nedl-us",
                  "_blank"
                )
              }
            >
              <LinkedInIcon />
            </MenuItem>
          </div>
          <div class="copyright">&#169; NEDL 2025</div>
          <div className="cus-flex">
            <MenuItem
              className="sub_btn"
              onClick={() =>
                window.open("https://nedl.us/terms-of-use/", "_blank")
              }
            >
              Terms Of Use
            </MenuItem>
            <MenuItem
              className="sub_btn"
              onClick={() =>
                window.open("https://nedl.us/privacy-policy/", "_blank")
              }
            >
              Privacy Policy
            </MenuItem>
          </div>
        </div>
      </div>
    );
  };
};

export default SubscriptionLayout;
