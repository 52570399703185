import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Avatar } from "primereact/avatar";
// import "./StripeForm.css";
import AddSubUserForm from "./AddSubUserForm";
import { Divider } from "primereact/divider";
import { InputSwitch } from "primereact/inputswitch";
import "primereact/resources/primereact.min.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "@mui/material";
import SubscriptionLayout from "../../Layout/subscriptionLayout";
const SubscriptionForm = () => {
  const navigate = useNavigate();
  const [checked2, setChecked2] = useState(false);
  const [msaPrice, setMsaPrice] = useState([99, 950, 1100]);
  const [regionPrice, setRegionPrice] = useState([199, 1910, 2300]);
  const [allPrice, setAllPrice] = useState([299, 2870, 3500]);
  const [subscriptionPlanMSAMonthly, setSubscriptionPlanMSAMonthly] =
    useState("true");
  const [subscriptionPlanRegionMonthly, setSubscriptionPlanRegionMonthly] =
    useState("true");
  const [subscriptionPlanALLMonthly, setSubscriptionPlanALLMonthly] =
    useState("true");
  const [finalAmt, setFinalAmt] = useState(null);
  const [finalSubscriptionFor, setFinalSubscriptionFor] = useState(null);
  const [finalPlan, setFinalPlan] = useState(null);
  const [showSelectSubForm, setShowSelectSubForm] = useState(false);
  const [msaHover, setMsaHover] = useState(false);
  const [regionHover, setRegionHover] = useState(false);
  const [AllHover, setAllHover] = useState(false);
  const [customHover, setCustomHover] = useState(false);
  const handleMSASubscribe = () => {
    const amount =
      subscriptionPlanMSAMonthly === "true" ? msaPrice[0] : msaPrice[1];
    const plan = subscriptionPlanMSAMonthly === "true" ? "monthly" : "yearly";
    setFinalSubscriptionFor("MSA");
    const urlNo = subscriptionPlanALLMonthly === "true" ? 1 : 4;
    setFinalAmt(amount);
    setFinalPlan(plan);
    setShowSelectSubForm(true);
    let url = `/subscription/${urlNo}`;

    navigate(url, {
      state: {
        subamount: amount,
        subplan: plan,
        subsubscriptionFor: "MSA",
        submsaMonthly: msaPrice[0],
        submsaYearly: msaPrice[1],
        subregionMonthly: regionPrice[0],
        subregionYearly: regionPrice[1],
        suballMonthly: allPrice[0],
        suballYearly: allPrice[1],
        clicked: "clicked",
      },
    });
  };

  const handleRegionSubscribe = () => {
    const amount =
      subscriptionPlanRegionMonthly === "true"
        ? regionPrice[0]
        : regionPrice[1];
    const plan =
      subscriptionPlanRegionMonthly === "true" ? "monthly" : "yearly";
    setFinalSubscriptionFor("Region");
    const urlNo = subscriptionPlanALLMonthly === "true" ? 2 : 5;
    setFinalAmt(amount);
    setFinalPlan(plan);
    setShowSelectSubForm(true);
    let url = `/subscription/${urlNo}`;

    navigate(url, {
      state: {
        subamount: amount,
        subplan: plan,
        subsubscriptionFor: "Region",
        submsaMonthly: msaPrice[0],
        submsaYearly: msaPrice[1],
        subregionMonthly: regionPrice[0],
        subregionYearly: regionPrice[1],
        suballMonthly: allPrice[0],
        suballYearly: allPrice[1],
        clicked: "clicked",
      },
    });
  };

  const handleAllSubscribe = () => {
    const amount =
      subscriptionPlanALLMonthly === "true" ? allPrice[0] : allPrice[1];
    const plan = subscriptionPlanALLMonthly === "true" ? "monthly" : "yearly";
    const urlNo = subscriptionPlanALLMonthly === "true" ? 3 : 6;
    setFinalSubscriptionFor("All");
    setFinalAmt(amount);
    setFinalPlan(plan);
    setShowSelectSubForm(true);
    let url = `/subscription/${urlNo}`;

    navigate(url, {
      state: {
        subamount: amount,
        subplan: plan,
        subsubscriptionFor: "All",
        submsaMonthly: msaPrice[0],
        submsaYearly: msaPrice[1],
        subregionMonthly: regionPrice[0],
        subregionYearly: regionPrice[1],
        suballMonthly: allPrice[0],
        suballYearly: allPrice[1],
        clicked: "clicked",
      },
    });
  };
  const handleLetsTalk = () => {
      // window.location.href = "https://nedl.us/enterprise/";
      window.open("https://nedl.us/enterprise/", "_blank");

  };
  useEffect(() => {
    handleAnnualPricing();
  }, [checked2]);
  const handleAnnualPricing = () => {
    if (checked2 === false) {
      setSubscriptionPlanMSAMonthly("true");
      setSubscriptionPlanRegionMonthly("true");
      setSubscriptionPlanALLMonthly("true");
    } else {
      setSubscriptionPlanMSAMonthly("false");
      setSubscriptionPlanRegionMonthly("false");
      setSubscriptionPlanALLMonthly("false");
    }
    {
      /* <div className="plan-selection">
            <div className="p-field-radiobutton">
                <RadioButton inputId="mm" name="msamonthly" value="true" onChange={(e) => setSubscriptionPlanMSAMonthly(e.value)} checked={subscriptionPlanMSAMonthly === 'true'} />
                <label htmlFor="mm">Monthly</label>
            </div>
            <div className="p-field-radiobutton">
                    <RadioButton inputId="my" name="msayearly" value="false" onChange={(e) => setSubscriptionPlanMSAMonthly(e.value)} checked={subscriptionPlanMSAMonthly === 'false'} />
                    <label htmlFor="my">Yearly</label>
            </div>
            </div> */
    }
  };
  const msaheader = (
    <div className={msaHover ? "card-header card-header-hover" : "card-header"}>
      <div className="card-title">
        <img
          className="card-header-img"
          src={
            msaHover
              ? "/images/payment/msahovericon.png"
              : "/images/payment/msanormalicon.png"
          }
          alt=""
        />
        <label>MSA</label>
      </div>
      <h1 class="card-pricing">
        <span class="dollar-sign">$</span>
        <span class="number">
          {subscriptionPlanMSAMonthly === "true" ? msaPrice[0] : msaPrice[1]}
        </span>
        <span
          class={
            subscriptionPlanMSAMonthly === "true" ? "per-month" : "per-year"
          }
        >
          {subscriptionPlanMSAMonthly === "true" ? "/per month" : "/per year"}
        </span>
      </h1>
      {checked2 === true ? (
        <label
          className={
            msaHover ? "strikeoutvalue strikeoutvalue-hover" : "strikeoutvalue"
          }
        >{`$${msaPrice[2]}`}</label>
      ) : null}
      {/* <div className="plan-selection">
            <div className="p-field-radiobutton">
                <RadioButton inputId="mm" name="msamonthly" value="true" onChange={(e) => setSubscriptionPlanMSAMonthly(e.value)} checked={subscriptionPlanMSAMonthly === 'true'} />
                <label htmlFor="mm">Monthly</label>
            </div>
            <div className="p-field-radiobutton">
                    <RadioButton inputId="my" name="msayearly" value="false" onChange={(e) => setSubscriptionPlanMSAMonthly(e.value)} checked={subscriptionPlanMSAMonthly === 'false'} />
                    <label htmlFor="my">Yearly</label>
            </div>
            </div> */}
      <button
        onClick={handleMSASubscribe}
        className={
          msaHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Subscribe Now
      </button>
    </div>
  );
  const regionheader = (
    <>
      <div
        className={
          regionHover ? "mostPopular-hover mostPopular" : "mostPopular"
        }
      >
        Most Popular
      </div>
      <div
        className={
          regionHover ? "card-header card-header-hover" : "card-header"
        }
      >
        <div style={{ paddingTop: "10px" }} className="card-title">
          <img
            className="card-header-img"
            src={
              regionHover
                ? "/images/payment/regionhovericon.png"
                : "/images/payment/regionnormalicon.png"
            }
            alt=""
          />
          <label>Region</label>
        </div>
        <h1 class="card-pricing">
          <span class="dollar-sign">$</span>
          <span class="number">
            {subscriptionPlanRegionMonthly === "true"
              ? regionPrice[0]
              : regionPrice[1]}
          </span>
          <span
            class={
              subscriptionPlanRegionMonthly === "true"
                ? "per-month"
                : "per-year"
            }
          >
            {subscriptionPlanRegionMonthly === "true"
              ? "/per month"
              : "/per year"}
          </span>
        </h1>
        {checked2 === true ? (
          <label
            className={
              regionHover
                ? "strikeoutvalue strikeoutvalue-hover"
                : "strikeoutvalue"
            }
          >{`$${regionPrice[2]}`}</label>
        ) : null}
        {/* <div className="plan-selection">
        <div className="p-field-radiobutton">
                    <RadioButton inputId="rm" name="regionmonthly" value="true" onChange={(e) => setSubscriptionPlanRegionMonthly(e.value)} checked={subscriptionPlanRegionMonthly === 'true'} />
                    <label htmlFor="rm">Monthly</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton inputId="ry" name="regionyearly" value="false" onChange={(e) => setSubscriptionPlanRegionMonthly(e.value)} checked={subscriptionPlanRegionMonthly === 'false'} />
                    <label htmlFor="ry">Yearly</label>
                </div>
            </div> */}
        <button
          onClick={handleRegionSubscribe}
          className={
            regionHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
          }
        >
          Subscribe Now
        </button>
      </div>
    </>
  );
  const allheader = (
    <div className={AllHover ? "card-header card-header-hover" : "card-header"}>
      <div className="card-title">
        <img
          className="card-header-img"
          src={
            AllHover
              ? "/images/payment/allhovericon.png"
              : "/images/payment/allnormalicon.png"
          }
          alt=""
        />
        <label>National</label>
      </div>
      <h1 class="card-pricing">
        <span class="dollar-sign">$</span>
        <span class="number">
          {subscriptionPlanALLMonthly === "true" ? allPrice[0] : allPrice[1]}
        </span>
        <span
          class={
            subscriptionPlanALLMonthly === "true" ? "per-month" : "per-year"
          }
        >
          {subscriptionPlanALLMonthly === "true" ? "/per month" : "/per year"}
        </span>
      </h1>
      {checked2 === true ? (
        <label
          className={
            AllHover ? "strikeoutvalue strikeoutvalue-hover" : "strikeoutvalue"
          }
        >{`$${allPrice[2]}`}</label>
      ) : null}
      {/* <div className="plan-selection">
        <div className="p-field-radiobutton">
                    <RadioButton inputId="am" name="allmonthly" value="true" onChange={(e) => setSubscriptionPlanALLMonthly(e.value)} checked={subscriptionPlanALLMonthly === 'true'} />
                    <label htmlFor="am">Monthly</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton inputId="ay" name="allyearly" value="false" onChange={(e) => setSubscriptionPlanALLMonthly(e.value)} checked={subscriptionPlanALLMonthly === 'false'} />
                    <label htmlFor="ay">Yearly</label>
                </div>
            </div> */}
      <button
        onClick={handleAllSubscribe}
        className={
          AllHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Subscribe Now
      </button>
    </div>
  );
  const customheader = (
    <div
      className={customHover ? "card-header card-header-hover" : "card-header"}
    >
      <div
        className={
          checked2 === false
            ? "card-title-custom"
            : "card-title-custom annual-card-title-custom"
        }
      >
        <img
          className="card-header-img"
          src={
            customHover
              ? "/images/payment/enterprisehovericon.png"
              : "/images/payment/enterprisenormalicon.png"
          }
          alt=""
        />
        <label>Enterprise</label>
      </div>
      <button
        onClick={handleLetsTalk}
        className={
          customHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"
        }
      >
        Let's Talk
      </button>
    </div>
  );

  const msafooter = (
    <div>
      <label
        className={
          msaHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={msaHover ? "custom-list custom-hover-list" : "custom-list"}
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 Metropolitan Statistical Area
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              msaHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
    // <button onClick={handleMSASubscribe}className={msaHover ? "subscribe-btn subscribe-btn-hover" : "subscribe-btn"}>GET STARTED</button>
  );
  const regionfooter = (
    <div>
      <label
        className={
          regionHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={
          regionHover ? "custom-list custom-hover-list" : "custom-list"
        }
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 Region
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              regionHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const allfooter = (
    <div>
      <label
        className={
          AllHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={AllHover ? "custom-list custom-hover-list" : "custom-list"}
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          1 License
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          All US Multifamily Properties
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              AllHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const customfooter = (
    <div className={checked2 === true ? "spacing-custom-annual" : null}>
      <label
        className={
          customHover ? "benefitslabel benefitslabel-hover" : "benefitslabel"
        }
      >
        Benefits Include:
      </label>
      <ul
        className={
          customHover ? "custom-list custom-hover-list" : "custom-list"
        }
        style={{ margin: "0", padding: "0" }}
      >
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Multiple Licenses
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Geography of Choice
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Professional Services
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Dedicated Accounted Team
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Dedicated Support Team
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Unlimited Intelligent Leads
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Recommended Buyers
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Deal Pipeline Management
        </li>
        <li>
          <img
            src={
              customHover
                ? "/images/payment/pointhovericon.png"
                : "/images/payment/pointnormalicon.png"
            }
            alt="."
            className="custom-list-benefit-icon"
          />
          Market Intelligence
        </li>
      </ul>
    </div>
  );
  const handleLabelClick = () => {
    setChecked2((prevChecked) => !prevChecked); // Toggle the switch state on label click
  };
  return (
    <>
      {/* <div class="sub_footer">
        <div class="Logo">
          <Avatar
            className="p-overlay-badge"
            image="\images\newlogo.png"
            size="xlarge"
          ></Avatar>
        </div>
        <div class="cus-flex">
          <MenuItem
            onClick={() =>
              window.open("https://www.instagram.com/nedl.us/", "_blank")
            }
          >
            <InstagramIcon />
          </MenuItem>
          <MenuItem
            onClick={() =>
              window.open("https://www.linkedin.com/company/nedl-us", "_blank")
            }
          >
            <LinkedInIcon />
          </MenuItem>
        </div>
        <div>
          <Button className="dealbtn" name="login">
            Login
          </Button>
        </div>
      </div> */}
      <div className="subscribeNowPage">
        <h4>Choose Your Plan</h4>
        <div className="custom-switch">
          <div className="input-switch-container">
            <span
              className={
                checked2 === false
                  ? "switch-label-left"
                  : "switch-label-left checkedfont"
              }
              onClick={handleLabelClick}
            >
              Monthly
            </span>
            <InputSwitch
              offLabel="Monthly"
              onLabel="Annual"
              checked={checked2}
              onChange={(e) => setChecked2(e.value)}
            />
            <span
              className={
                checked2 === false
                  ? "switch-label-right checkedfont"
                  : "switch-label-right"
              }
              onClick={handleLabelClick}
            >
              Annual
            </span>
          </div>
        </div>
        <div className="chooseSubMainContainer">
          <div
            className="SubBtnCard"
            onMouseEnter={() => setMsaHover(true)}
            onMouseLeave={() => setMsaHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: msaHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={msafooter}
              header={msaheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setRegionHover(true)}
            onMouseLeave={() => setRegionHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: regionHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard sub-highlight"
              footer={regionfooter}
              header={regionheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setAllHover(true)}
            onMouseLeave={() => setAllHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: AllHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={allfooter}
              header={allheader}
            ></Card>
          </div>
          <div
            className="SubBtnCard"
            onMouseEnter={() => setCustomHover(true)}
            onMouseLeave={() => setCustomHover(false)}
          >
            <Card
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: customHover
                  ? "url(/images/payment/monthlyhovercard.png)"
                  : "",
              }}
              className="subcard"
              footer={customfooter}
              header={customheader}
            ></Card>
          </div>
        </div>
      </div>
      {/*      
      <div class="sub_footer">
        <div class="cus-flex">
          <MenuItem
            onClick={() =>
              window.open("https://www.instagram.com/nedl.us/", "_blank")
            }
          >
            <InstagramIcon />
          </MenuItem>
          <MenuItem
            onClick={() =>
              window.open("https://www.linkedin.com/company/nedl-us", "_blank")
            }
          >
            <LinkedInIcon />
          </MenuItem>
        </div>
        <div class="copyright">&#169; NEDL 2021</div>
        <div className="cus-flex">
          <MenuItem
            onClick={() =>
              window.open("https://nedl.us/terms-of-use/", "_blank")
            }
          >
            Terms Of Use
          </MenuItem>
          <MenuItem
            onClick={() =>
              window.open("https://nedl.us/privacy-policy/", "_blank")
            }
          >
            Privacy Plicy
          </MenuItem>

        
        </div>
      </div> */}
    </>
  );
};

export default SubscriptionLayout(SubscriptionForm);
