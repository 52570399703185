
const PageNotFound = () => {
    return(
<div className="w100 h100">
<div style={{backgroundImage:"url('/images/lattest/bgimage.svg')",width:"100%",minHeight:"96vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
<div style={{fontFamily:"Calibri",width:"50%",padding:"10px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
  <img src="/images/lattest/mainimg.png" alt="Image" />
 <h1 style={{fontSize:"42px",marginBottom:"0px"}}>404 Page Not Found</h1>
<p style={{width:"100%",marginTop:"5px",wordWrap:"break-word",textAlign:"center",fontSize:"18px",fontWeight:"400",color:"darkgray"}}>
   We're sorry, the page you requested could not be found <br />Please go back to the homepage.
    </p>
</div>
</div>
</div>
    );
}
export default PageNotFound;